import React from 'react';
import { motion } from 'framer-motion';
import ServicesContact from '../components/SidePages/ServicesContact';
import ContactInfo from '../components/ContactInfo';
import ContactMap from '../components/ContactMap';

const Contact = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <ServicesContact />
      <ContactInfo />
      <ContactMap />
    </motion.div>
  );
};

const ContactHero = () => {
  return (
    <section className="relative h-[40vh] flex items-center justify-center overflow-hidden bg-primary">
      <div className="absolute z-0 w-full h-full">
        <img src="/4Q.jpg" alt="Contact Us" className="w-full h-full object-cover opacity-30" />
      </div>
      <div className="relative z-20 text-center text-white max-w-4xl mx-auto px-4 md:px-6">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4"
        >
          Contact Us
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="text-xl md:text-2xl"
        >
          We're here to help with all your insurance needs
        </motion.p>
      </div>
    </section>
  );
};

export default Contact;