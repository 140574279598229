import React, { useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

const About = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });
  const controls = useAnimation();

  React.useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.6, -0.05, 0.01, 0.99], // Custom easing function for smooth transition
      },
    },
  };

  return (
    <section ref={ref} className="py-16 md:py-20 bg-background-lightest text-white relative overflow-hidden">
      <motion.div
        className="absolute inset-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1 }}
        style={{
          backgroundImage: "url('/abstract-pattern.svg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div className="container mx-auto px-4 md:px-6 relative z-10">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          className="max-w-3xl mx-auto text-center"
        >
          <motion.h2 variants={itemVariants} className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-white">
            About RPIS
          </motion.h2>
          <motion.p variants={itemVariants} className="text-lg md:text-xl mb-4 md:mb-6">
          We have over 40 years of insurance experience helping clients prepare for the unknown. Inquire about Home, Auto, Fire, Flood, Business, Health, Theft,Life, and much more! 
          </motion.p>
          <motion.p variants={itemVariants} className="text-lg md:text-xl mb-4 md:mb-6">
          We are a full-service insurance agency that has represented California and other demographics since 1981. Our philosophical approach is, we represent our clients first! 
          </motion.p>
          <motion.p variants={itemVariants} className="text-lg md:text-xl mb-6 md:mb-8">
          To accomplish these goals, we represent a large number of different insurance companies so that we're able to find our clients the best value to meet their needs.
          </motion.p>
          <motion.button
            variants={itemVariants}
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(255,255,255)" }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.location.href = "/about"}
            className="bg-primary text-quaternary px-6 md:px-8 py-2 md:py-3 rounded-md font-semibold text-base md:text-lg hover:bg-secondary transition duration-300"
          >
            Learn More About Us
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default About;