import React from 'react';
import { motion } from 'framer-motion';

const ContactMap = () => {
  return (
    <section className="py-20 bg-background-dark pt-0">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-accent"
        >
          Our Location
        </motion.h2>
        <div className="relative h-96 rounded-lg overflow-hidden">
          <embed
            src="https://www.openstreetmap.org/export/embed.html?bbox=-74.0060%2C40.7128%2C-73.9352%2C40.7306&layer=mapnik"
            className="w-full h-full"
          />
        </div>
        <p className="text-center text-text-light mt-4">
          123 Random St, New York, NY 10001
        </p>
      </div>
    </section>
  );
};

export default ContactMap;