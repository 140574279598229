// ../pages/AutoInsurance.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import InsuranceTypePage from './InsuranceTypePage';
import Services from '../components/Services';
import CustomDropdown from '../components/CustomDropdown'; // Import CustomDropdown

const AutoInsurance = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    dateOfBirth: '',
    licenseNumber: '',
    vehicleMakeModel: '',
    vehicleYear: '',
    vin: '',
    currentProvider: '',
    coverageLevel: [],
    annualMileage: '',
    accidents: [],
    violations: [],
  });

  const [submitMessage, setSubmitMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (fieldName, selectedOptions) => {
    setFormData({ ...formData, [fieldName]: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await axios.post('/api/send-email', {
        formType: 'auto',
        formData: formData
      });

      if (response.data.message === 'Email sent successfully') {
        setSubmitMessage('Your auto insurance quote has been submitted successfully!');
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          address: '',
          dateOfBirth: '',
          licenseNumber: '',
          vehicleMakeModel: '',
          vehicleYear: '',
          vin: '',
          currentProvider: '',
          coverageLevel: [],
          annualMileage: '',
          accidents: [],
          violations: [],
        });
      } else {
        setSubmitMessage('Failed to submit your quote. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred while submitting your quote.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageProps = {
    title: "Auto Insurance Solutions",
    description:
      "At RPIS, we work with multiple carriers to ensure you get the best auto insurance coverage that fits your lifestyle and budget. Whether you're a daily commuter or an occasional driver, we’ve got you covered.",
    customDescription:
      "We offer flexible auto insurance solutions designed to meet your specific driving habits and coverage needs. Our goal is to find the right policy that provides protection and peace of mind.",
    benefits: [
      "Access to multiple carriers for the best rates.",
      "Customizable coverage options based on your driving needs.",
      "Affordable premiums with flexible payment plans.",
      "Expert assistance in choosing the right policy.",
      "Quick and easy claims process.",
    ],
    coverageItems: [
      "Liability Coverage",
      "Comprehensive and Collision",
      "Uninsured/Underinsured Motorist Coverage",
      "Roadside Assistance",
      "Rental Reimbursement",
    ],
    imageSrc: "/999q.jpg",
    carriers: [
      { name: "ProGressive", logo: "/pg.png" },
      { name: "StateFarm", logo: "/sf.png" },
      { name: "Geico", logo: "/gc.png" },
      { name: "AllState", logo: "/as.png" },
      { name: "USAA", logo: "/ua.png" },
    ]
  };

  const formFields = [
    { name: 'fullName', label: 'Full Name', type: 'text', required: true },
    { name: 'email', label: 'Email Address', type: 'email', required: true },
    { name: 'phone', label: 'Phone Number', type: 'tel', required: true },
    { name: 'address', label: 'Address', type: 'text', required: true },
    { name: 'dateOfBirth', label: 'Date of Birth', type: 'date', required: true },
    { name: 'licenseNumber', label: "Driver's License Number", type: 'text', required: true },
    { name: 'vehicleMakeModel', label: 'Vehicle Make & Model', type: 'text', required: true },
    { name: 'vehicleYear', label: 'Year of Vehicle', type: 'number', required: true },
    { name: 'vin', label: 'Vehicle Identification Number (VIN)', type: 'text', required: true },
    { name: 'currentProvider', label: 'Current Auto Insurance Provider', type: 'text', required: false },
    // Select fields will be handled separately
    { name: 'coverageLevel', label: 'Coverage Level Desired', type: 'custom-select', options: ['Liability', 'Collision', 'Comprehensive'], required: true },
    { name: 'annualMileage', label: 'Estimated Annual Mileage', type: 'number', required: true },
    { name: 'accidents', label: 'Any Accidents/Claims in the Last 5 Years?', type: 'custom-select', options: ['Yes', 'No'], required: true },
    { name: 'violations', label: 'Any Traffic Violations in the Last 5 Years?', type: 'custom-select', options: ['Yes', 'No'], required: true },
  ];

  const coverageLevelOptions = [
    { value: 'Liability', label: 'Liability' },
    { value: 'Collision', label: 'Collision' },
    { value: 'Comprehensive', label: 'Comprehensive' },
  ];

  const accidentsOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const violationsOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  return (
    <div>
      <InsuranceTypePage {...pageProps} />
      <section className="py-20 bg-background-dark" id='quote-form'>
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-center mb-12 text-white"
          >
            Get Your Auto Insurance Quote
          </motion.h2>
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onSubmit={handleSubmit}
            className="max-w-3xl mx-auto bg-background-light p-8 rounded-lg shadow-md border-4 border-accent"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {formFields.map((field) => (
                field.type !== 'custom-select' ? (
                  <motion.div
                    key={field.name}
                    whileHover={field.type !== 'textarea' ? { scale: 1.05 } : {}}
                    whileTap={field.type !== 'textarea' ? { scale: 0.95 } : {}}
                    className="flex flex-col"
                  >
                    <label htmlFor={field.name} className="block text-text-dark mb-2">
                      {field.label}
                      {field.required && <span className="text-red-500">*</span>}
                    </label>
                    {field.type === 'textarea' ? (
                      <textarea
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                        rows="3"
                      />
                    ) : (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                      />
                    )}
                  </motion.div>
                ) : (
                  <CustomDropdown
                    key={field.name}
                    label={field.label}
                    options={
                      field.name === 'coverageLevel'
                        ? coverageLevelOptions
                        : field.name === 'accidents'
                        ? accidentsOptions
                        : violationsOptions
                    }
                    selected={formData[field.name]}
                    onSelect={(selected) => handleSelect(field.name, selected)}
                    isMultiSelect={false} // Set to true if multi-select is needed
                    placeholder={`Select ${field.label}`}
                  />
                )
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="mt-8 w-full bg-accent text-white font-bold py-2 px-6 rounded-md hover:bg-accent-light transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Get Your Quote'}
            </motion.button>
            {submitMessage && (
              <p className="mt-4 text-center text-white">{submitMessage}</p>
            )}
          </motion.form>
        </div>
      </section>
      <Services header="Types of Insurance" />
    </div>
  );
};

export default AutoInsurance;
