import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const CustomDropdown = ({
  label,
  options,
  selected,
  onSelect,
  isMultiSelect = false,
  placeholder = 'Select...',
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionClick = (optionValue) => {
    if (isMultiSelect) {
      if (selected.includes(optionValue)) {
        onSelect(selected.filter((val) => val !== optionValue));
      } else {
        onSelect([...selected, optionValue]);
      }
    } else {
      if (selected[0] === optionValue) {
        onSelect([]); // Deselect if already selected
      } else {
        onSelect([optionValue]); // Single selection wrapped in array
      }
      setDropdownOpen(false);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const displayText = () => {
    if (selected.length === 0) return placeholder;
    if (isMultiSelect) {
      return options
        .filter((option) => selected.includes(option.value))
        .map((option) => option.label)
        .join(', ');
    }
    const selectedOption = options.find((option) => option.value === selected[0]);
    return selectedOption ? selectedOption.label : placeholder;
  };

  return (
    <div className="mb-6 relative" ref={dropdownRef}>
      {label && (
        <label className="block text-text-dark mb-2" htmlFor={label}>
          {label}
        </label>
      )}
      <div
        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md cursor-pointer flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <span>{displayText()}</span>
        <svg
          className={`h-5 w-5 transition-transform duration-200 ${
            dropdownOpen ? 'transform rotate-180' : ''
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>

      {dropdownOpen && (
        <ul className="absolute w-full bg-background-light border-4 border-accent rounded-md mt-2 z-10 max-h-60 overflow-y-auto">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
              className="flex items-center p-2 cursor-pointer hover:bg-gray-600 transition duration-300"
            >
              {selected.includes(option.value) && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2 text-accent"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              )}
              <span className="text-white">{option.label}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

CustomDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default CustomDropdown;