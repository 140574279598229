import React, { useRef, useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

const historyEvents = [
  { year: 1980, event: 'Founded by John Doe as a local insurance agency in California' },
  { year: 1990, event: 'Expanded to offer both personal and commercial insurance services' },
  { year: 2000, event: 'Reached coverage in 20 states, providing nationwide insurance solutions' },
  { year: 2008, event: 'Appointed to 50+ top rate carriers' },
  { year: 2021, event: 'Celebrated 40 years of dedicated service with over 1,000+ satisfied customers' },
  { year: 2024, event: 'Launched our customer-friendly online insurance quoting system' },
];

const HistoryItem = ({ event, index, scrollYProgress, isMobile }) => {
  const itemHeight = 1 / historyEvents.length;
  const start = Math.max(0, index * itemHeight - itemHeight * 0.5);
  const end = index * itemHeight + itemHeight * 0.5;

  const opacity = useTransform(scrollYProgress, [start, end], [0.3, 1]);
  const scale = useTransform(scrollYProgress, [start, end], [0.8, 1]);

  return (
    <motion.div
      style={{ opacity, scale }}
      className={`flex items-center mb-12 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}
    >
      <div className={`w-1/2 ${index % 2 === 0 ? 'pr-8 text-right' : 'pl-8'} flex flex-col items-left relative`}>
        <span className="text-4xl font-bold text-accent-light">{event.year}</span>
        <div className="mt-2 bg-dark bg-opacity-80 rounded-lg inline-block">
          <p className="text-sm text-text-light">{event.event}</p>
        </div>
      </div>
      <motion.div
        className="w-3 h-3 bg-white rounded-full absolute left-1/2 transform -translate-x-1/2"
        style={{ opacity }}
      />
    </motion.div>
  );
};

const OurHistory = () => {
  const containerRef = useRef(null);
  const lineRef = useRef(null);
  const [lineLength, setLineLength] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  useEffect(() => {
    if (lineRef.current) {
      setLineLength(lineRef.current.getTotalLength());
    }
  }, [isMobile]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start 40%', 'end 60%'], // Modified this line to speed up the animation
  });

  const lineHeight = useTransform(
    scrollYProgress,
    [0, 1],
    ['0%', '100%']
  );

  return (
    <section ref={containerRef} className="py-20 bg-timeline bg-gradient-to-br from-background-light to-background-dark text-white relative overflow-hidden">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-4xl font-bold text-center mb-16 text-gray-100"
        >
          Our Journey
        </motion.h2>
        <div className="relative max-w-2xl mx-auto">
          <motion.div
            className="absolute left-1/2 transform -translate-x-1/2 w-0.5 bg-gray-100"
            style={{ height: lineHeight }}
          />
          
          {historyEvents.map((event, index) => (
            <HistoryItem
              key={index}
              event={event}
              index={index}
              scrollYProgress={scrollYProgress}
              isMobile={isMobile}
            />
          ))}
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-accent-light text-white font-semibold py-3 px-6 rounded-lg mt-12 mx-auto block opacity-100"
          onClick={() => window.location.href = '/services'}
        >
          Explore Our Services
        </motion.button>
      </div>
    </section>
  );
};

export default OurHistory;