import React from 'react';
import { motion } from 'framer-motion';

const testimonials = [
  { name: 'Brian Carter', comment: 'From the initial call to the final resolution, the experience was seamless.', avatar: '/api/placeholder/50/50' },
  { name: 'Rachel Morris', comment: 'I was very pleased with how quickly everything was taken care of.', avatar: '/api/placeholder/50/50' },
  { name: 'Tommy Green', comment: "They made a complex process feel simple and easy. Excellent work!", avatar: '/api/placeholder/50/50' },
  { name: 'Olivia Stewart', comment: 'They handled my needs perfectly with fast and friendly service.', avatar: '/api/placeholder/50/50' },
  { name: 'Laura Evans', comment: 'Quick, efficient, and easy to work with. Highly recommend their services.', avatar: '/api/placeholder/50/50' },
  { name: 'Jake Peterson', comment: 'The team was incredibly professional and guided me through every step of the process.', avatar: '/api/placeholder/50/50' },
];

const ServicesTestimonials = () => {
  return (
    <section className="py-20 bg-background-light">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-white"
        >
          What Our Clients Say
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
              className="bg-background-dark p-6 rounded-lg shadow-md border-4 border-white"
            >
              <div className="flex items-left mb-4 flex-col">
                <h3 className="font-semibold text-white">{testimonial.name}</h3>
                <div className="text-yellow-400">★★★★★</div>
              </div>
              <p className="text-text-light italic">"{testimonial.comment}"</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesTestimonials;