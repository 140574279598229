import React from 'react';
import { motion } from 'framer-motion';
import ServicesHero from '../components/SidePages/ServicesHero';
import Services from '../components/Services';
import ServicesBenefits from '../components/SidePages/ServicesBenefits';
import ServicesProcess from '../components/SidePages/ServicesProcess';
import ServicesFAQ from '../components/SidePages/ServicesFAQ';
import ServicesTestimonials from '../components/SidePages/ServicesTestimonials';
import ServicesContact from '../components/SidePages/ServicesContact';

const Services_ = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Services />
      <ServicesProcess />
      <ServicesBenefits />
      <ServicesFAQ />
      <ServicesTestimonials />
      <ServicesContact />
    </motion.div>
  );
};

export default Services_;