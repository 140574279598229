// ../pages/HealthInsurance.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import InsuranceTypePage from './InsuranceTypePage';
import Services from '../components/Services';
import CustomDropdown from '../components/CustomDropdown'; // Import CustomDropdown

const HealthInsurance = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    dateOfBirth: '',
    gender: [],
    email: '',
    phone: '',
    address: '',
    currentProvider: '',
    startDate: '',
    coverageType: [],
    preExistingConditions: [],
    tobaccoUse: [],
    healthConcerns: '',
  });

  const [submitMessage, setSubmitMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (fieldName, selectedOptions) => {
    setFormData({ ...formData, [fieldName]: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await axios.post('/api/send-email', {
        formType: 'health',
        formData: formData
      });

      if (response.data.message === 'Email sent successfully') {
        setSubmitMessage('Your health insurance quote has been submitted successfully!');
        setFormData({
          fullName: '',
          dateOfBirth: '',
          gender: [],
          email: '',
          phone: '',
          address: '',
          currentProvider: '',
          startDate: '',
          coverageType: [],
          preExistingConditions: [],
          tobaccoUse: [],
          healthConcerns: '',
        });
      } else {
        setSubmitMessage('Failed to submit your quote. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred while submitting your quote.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageProps = {
    title: "Health Insurance Solutions",
    description:
      "Protecting your health and well-being is our top priority. Our health insurance plans are designed to provide peace of mind, with comprehensive coverage tailored to your unique needs.",
    customDescription:
      "We provide health insurance plans designed to meet your unique health needs and budget. Whether you're looking for individual coverage or a family plan, we’ll help you find the right policy.",
    benefits: [
      "Access to top carriers for the best health insurance rates.",
      "Flexible coverage options to meet your specific health needs.",
      "Affordable premiums with various payment options.",
      "Guidance from experts to choose the best plan for you and your family.",
      "Support during the claims process.",
    ],
    coverageItems: [
      "Individual and Family Plans",
      "Health Maintenance Organization (HMO)",
      "Preferred Provider Organization (PPO)",
      "High Deductible Health Plans (HDHP)",
      "Dental and Vision Coverage",
    ],
    imageSrc: "/4Q.jpg",
    carriers: [
      { name: "ProGressive", logo: "/pg.png" },
      { name: "StateFarm", logo: "/sf.png" },
      { name: "Geico", logo: "/gc.png" },
      { name: "AllState", logo: "/as.png" },
      { name: "USAA", logo: "/ua.png" },
    ],
  };

  const formFields = [
    { name: 'fullName', label: 'Full Name', type: 'text', required: true },
    { name: 'dateOfBirth', label: 'Date of Birth', type: 'date', required: true },
    { name: 'gender', label: 'Gender', type: 'custom-select', options: ['Male', 'Female', 'Other'], required: false },
    { name: 'email', label: 'Email Address', type: 'email', required: true },
    { name: 'phone', label: 'Phone Number', type: 'tel', required: true },
    { name: 'address', label: 'Address', type: 'text', required: true },
    { name: 'currentProvider', label: 'Current Health Insurance Provider', type: 'text', required: false },
    { name: 'startDate', label: 'Desired Coverage Start Date', type: 'date', required: true },
    { name: 'coverageType', label: 'Type of Coverage', type: 'custom-select', options: ['Individual', 'Family', 'Group'], required: true },
    { name: 'preExistingConditions', label: 'Do You Have Any Pre-existing Conditions?', type: 'custom-select', options: ['Yes', 'No'], required: false },
    { name: 'tobaccoUse', label: 'Tobacco Use', type: 'custom-select', options: ['Yes', 'No'], required: true },
    { name: 'healthConcerns', label: 'Any Specific Health Concerns or Requirements?', type: 'textarea', required: false },
  ];

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
  ];

  const coverageTypeOptions = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Family', label: 'Family' },
    { value: 'Group', label: 'Group' },
  ];

  const preExistingConditionsOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const tobaccoUseOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  return (
    <div>
      <InsuranceTypePage {...pageProps} />
      <section className="py-20 bg-background-dark">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-center mb-12 text-white"
          >
            Get Your Health Insurance Quote
          </motion.h2>
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onSubmit={handleSubmit}
            className="max-w-3xl mx-auto bg-background-light p-8 rounded-lg shadow-md border-4 border-accent"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {formFields.map((field) => (
                field.type !== 'custom-select' ? (
                  <motion.div
                    key={field.name}
                    whileHover={field.type !== 'textarea' ? { scale: 1.05 } : {}}
                    whileTap={field.type !== 'textarea' ? { scale: 0.95 } : {}}
                    className="flex flex-col"
                  >
                    <label htmlFor={field.name} className="block text-text-dark mb-2">
                      {field.label}
                      {field.required && <span className="text-red-500">*</span>}
                    </label>
                    {field.type === 'textarea' ? (
                      <textarea
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                        rows="3"
                      />
                    ) : (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                      />
                    )}
                  </motion.div>
                ) : (
                  <CustomDropdown
                    key={field.name}
                    label={field.label}
                    options={
                      field.name === 'gender'
                        ? genderOptions
                        : field.name === 'coverageType'
                        ? coverageTypeOptions
                        : field.name === 'preExistingConditions'
                        ? preExistingConditionsOptions
                        : tobaccoUseOptions
                    }
                    selected={formData[field.name]}
                    onSelect={(selected) => handleSelect(field.name, selected)}
                    isMultiSelect={false} // Set to true if multi-select is needed
                    placeholder={`Select ${field.label}`}
                  />
                )
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="mt-8 w-full bg-accent text-white font-bold py-2 px-6 rounded-md hover:bg-accent-light transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Get Your Quote'}
            </motion.button>
            {submitMessage && (
              <p className="mt-4 text-center text-white">{submitMessage}</p>
            )}
          </motion.form>
        </div>
      </section>
      <Services header="Types of Insurance" />
    </div>
  );
};

export default HealthInsurance;
