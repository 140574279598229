// ../pages/HomeInsurance.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import InsuranceTypePage from './InsuranceTypePage';
import Services from '../components/Services';
import CustomDropdown from '../components/CustomDropdown'; // Import CustomDropdown

const HomeInsurance = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    propertyType: [],
    ownershipStatus: [],
    yearBuilt: '',
    squareFootage: '',
    propertyValue: '',
    hasMortgage: [],
    coverageLevel: [],
    currentProvider: '',
    recentClaims: [],
  });

  const [submitMessage, setSubmitMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (fieldName, selectedOptions) => {
    setFormData({ ...formData, [fieldName]: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await axios.post('/api/send-email', {
        formType: 'home',
        formData: formData
      });

      if (response.data.message === 'Email sent successfully') {
        setSubmitMessage('Your home insurance quote has been submitted successfully!');
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          address: '',
          propertyType: [],
          ownershipStatus: [],
          yearBuilt: '',
          squareFootage: '',
          propertyValue: '',
          hasMortgage: [],
          coverageLevel: [],
          currentProvider: '',
          recentClaims: [],
        });
      } else {
        setSubmitMessage('Failed to submit your quote. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred while submitting your quote.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageProps = {
    title: "Property Insurance Solutions",
    description:
      "At RPIS, we provide property insurance that gives you peace of mind, knowing your home or investment is protected. By working with multiple carriers, we ensure you get the best rates and coverage options",
    customDescription:
      "We understand that every property is unique, and our solutions are tailored to meet your specific needs. Whether it’s your home, rental property, or investment property, we offer coverage that fits your requirements.",
    benefits: [
      "Multiple carriers provide competitive rates.",
      "Coverage tailored to your property type and value.",
      "Affordable premiums with flexible payment options.",
      "Expert advice to find the right policy.",
      "Seamless claims process for property damage.",
    ],
    coverageItems: [
      "Homeowners Insurance",
      "Renters Insurance",
      "Landlord Insurance",
      "Flood Insurance",
      "Umbrella Policies",
    ],
    imageSrc: "/4Q.jpg",
    carriers: [
      { name: "ProGressive", logo: "/pg.png" },
      { name: "StateFarm", logo: "/sf.png" },
      { name: "Geico", logo: "/gc.png" },
      { name: "AllState", logo: "/as.png" },
      { name: "USAA", logo: "/ua.png" },
    ],
  };

  const formFields = [
    { name: 'fullName', label: 'Full Name', type: 'text', required: true },
    { name: 'email', label: 'Email Address', type: 'email', required: true },
    { name: 'phone', label: 'Phone Number', type: 'tel', required: true },
    { name: 'address', label: 'Address of Property', type: 'text', required: true },
    { name: 'ownershipStatus', label: 'Is This a Rental or Owned Property?', type: 'custom-select', options: ['Owned', 'Rental'], required: true },
    { name: 'propertyType', label: 'Type of Property', type: 'custom-select', options: ['Single-family', 'Condo', 'Apartment', 'Other'], required: true },
    { name: 'yearBuilt', label: 'Year Built', type: 'number', required: true },
    { name: 'squareFootage', label: 'Square Footage', type: 'number', required: true },
    { name: 'propertyValue', label: 'Estimated Property Value', type: 'number', required: true },
    { name: 'hasMortgage', label: 'Do You Have a Mortgage?', type: 'custom-select', options: ['Yes', 'No'], required: true },
    { name: 'coverageLevel', label: 'Coverage Level Desired', type: 'custom-select', options: ['Basic', 'Standard', 'Premium'], required: true },
    { name: 'currentProvider', label: 'Current Home Insurance Provider', type: 'text', required: false },
    { name: 'recentClaims', label: 'Any Recent Claims or Repairs?', type: 'custom-select', options: ['Yes', 'No'], required: true },
  ];

  const ownershipStatusOptions = [
    { value: 'Owned', label: 'Owned' },
    { value: 'Rental', label: 'Rental' },
  ];

  const propertyTypeOptions = [
    { value: 'Single-family', label: 'Single-family' },
    { value: 'Condo', label: 'Condo' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Other', label: 'Other' },
  ];

  const hasMortgageOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const coverageLevelOptions = [
    { value: 'Basic', label: 'Basic' },
    { value: 'Standard', label: 'Standard' },
    { value: 'Premium', label: 'Premium' },
  ];

  const recentClaimsOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  return (
    <div>
      <InsuranceTypePage {...pageProps} />
      <section className="py-20 bg-background-dark">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-center mb-12 text-white"
          >
            Get Your Home Insurance Quote
          </motion.h2>
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onSubmit={handleSubmit}
            className="max-w-3xl mx-auto bg-background-light p-8 rounded-lg shadow-md border-4 border-accent"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {formFields.map((field) => (
                field.type !== 'custom-select' ? (
                  <motion.div
                    key={field.name}
                    whileHover={field.type !== 'textarea' ? { scale: 1.05 } : {}}
                    whileTap={field.type !== 'textarea' ? { scale: 0.95 } : {}}
                    className="flex flex-col"
                  >
                    <label htmlFor={field.name} className="block text-text-dark mb-2">
                      {field.label}
                      {field.required && <span className="text-red-500">*</span>}
                    </label>
                    {field.type === 'textarea' ? (
                      <textarea
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                        rows="3"
                      />
                    ) : (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                      />
                    )}
                  </motion.div>
                ) : (
                  <CustomDropdown
                    key={field.name}
                    label={field.label}
                    options={
                      field.name === 'ownershipStatus'
                        ? ownershipStatusOptions
                        : field.name === 'propertyType'
                        ? propertyTypeOptions
                        : field.name === 'hasMortgage'
                        ? hasMortgageOptions
                        : coverageLevelOptions
                    }
                    selected={formData[field.name]}
                    onSelect={(selected) => handleSelect(field.name, selected)}
                    isMultiSelect={false} // Set to true if multi-select is needed
                    placeholder={`Select ${field.label}`}
                  />
                )
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="mt-8 w-full bg-accent text-white font-bold py-2 px-6 rounded-md hover:bg-accent-light transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Get Your Quote'}
            </motion.button>
            {submitMessage && (
              <p className="mt-4 text-center text-white">{submitMessage}</p>
            )}
          </motion.form>
        </div>
      </section>
      <Services header="Types of Insurance" />
    </div>
  );
};

export default HomeInsurance;
