import React, { useRef, useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

const serviceItems = [
  { title: 'Health Insurance', description: 'Coverage for medical expenses and health-related costs.', icon: 'fi fi-rr-hospital' },
  { title: 'Life Insurance', description: "Financial protection in the event of the policyholder's death.", icon: 'fi fi-rr-suitcase-alt' },
  { title: 'Auto Insurance', description: 'Coverage for vehicles, comprehensive protection plans.', icon: 'fi fi-ss-steering-wheel' },
  { title: 'Business Insurance', description: 'Coverage for businesses, liability, property and more.', icon: 'fi fi-rs-city' },
  { title: 'Fire Insurance', description: 'Protects your property from damage and loss due to fire.', icon: 'fi fi-rs-flame' },
  { title: 'Property Insurance', description: 'Protection for homes and personal property against damage.', icon: 'fi fi-rr-house-chimney' },
];

const Services = ({ backgroundImage, header }) => {
  const ref = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        const items = ref.current.querySelectorAll('.service-item');
        const activeItem = Array.from(items).findIndex(item => {
          const rect = item.getBoundingClientRect();
          return rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2;
        });
        if (activeItem !== -1) {
          setActiveIndex(activeItem);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isMobile]);

  const style = {
    backgroundImage: `url("${backgroundImage}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
  };

  if (!backgroundImage) {
    // Remove background styles if no image is provided
    delete style.backgroundImage;
    delete style.backgroundSize;
    delete style.backgroundPosition;
    delete style.backgroundAttachment;
  }

  if (!header) {
    header = 'Insurance Solutions to Match Your Needs';
  }

  // Animation variants for synchronized animation
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0, // No stagger, synchronize animations
      },
    },
  };

  const rowVariantsLeft = {
    hidden: { x: '-100%', opacity: 0 },
    visible: { x: '0%', opacity: 1 },
  };

  const rowVariantsRight = {
    hidden: { x: '100%', opacity: 0 },
    visible: { x: '0%', opacity: 1 },
  };

  const animationTransition = {
    duration: 1,
    ease: 'easeOut',
  };

  return (
    <section 
      ref={ref} 
      className="py-20 bg-background-light relative overflow-hidden pt-15"
      style={style}
    >
      {/* Background Overlays */}
      <motion.div
        className="absolute inset-0 bg-background-light opacity-80"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.8 }}
        transition={{ duration: 1 }}
      />
      
      <motion.div
        className="absolute inset-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.05 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <div className="w-full h-full bg-repeat" />
      </motion.div>
      
      {/* Content Container */}
      <div className="container mx-auto px-6 relative z-10">
        {/* Header */}
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: [0.6, -0.05, 0.01, 0.99] }}
          className="text-3xl font-bold text-center mb-12 text-white"
        >
          {header}
        </motion.h2>
        
        {/* Service Items */}
        {isMobile ? (
          // Mobile View: Scroll-Based Active Item Highlighting
          <div className="space-y-8">
            {serviceItems.map((item, index) => (
              <ServiceItem 
                key={index} 
                item={item} 
                isMobile={true} 
                index={index}
                isActive={index === activeIndex}
              />
            ))}
          </div>
        ) : (
          // Desktop View: Synchronized Slide and Fade-In Animations
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="overflow-hidden"
          >
            {/* First Row - Slide in from Left */}
            <motion.div
              variants={rowVariantsLeft}
              transition={animationTransition}
              className="flex mb-8"
            >
              {serviceItems.slice(0, 3).map((item, index) => (
                <ServiceItem key={index} item={item} isMobile={false} />
              ))}
            </motion.div>
            
            {/* Second Row - Slide in from Right */}
            <motion.div
              variants={rowVariantsRight}
              transition={animationTransition}
              className="flex"
            >
              {serviceItems.slice(3).map((item, index) => (
                <ServiceItem key={index + 3} item={item} isMobile={false} />
              ))}
            </motion.div>
          </motion.div>
        )}

        {/* Explore Our Services Button */}
        <motion.button
          className="bg-accent text-white rounded-lg shadow-md hover:bg-accent-dark transition duration-300 py-3 px-6 font-semibold mt-12 mx-auto block"
          onClick={() => window.location.href = '/types-of-insurance'}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Explore Our Services
        </motion.button>
      </div>
    </section>
  );
};

const ServiceItem = ({ item, isMobile, index, isActive }) => {
  const controls = useAnimation();

  useEffect(() => {
    if (isMobile) {
      controls.start({
        x: isActive ? 0 : 20,
        opacity: isActive ? 1 : 0.5,
        transition: { duration: 0.3 }
      });
    }
  }, [isMobile, isActive, controls]);

  return (
    <motion.div 
      className={`service-item ${isMobile ? 'w-full' : 'flex-shrink-0 w-1/3'} p-4`}
      initial={isMobile ? { opacity: 0.5, x: 20 } : {}}
      animate={isMobile ? controls : {}}
    >
      <motion.div 
        className={`bg-background p-6 rounded-lg shadow-md cursor-pointer transform transition-all duration-300 relative group h-full border-4 ${
          !isMobile || isActive ? 'border-accent' : 'border-transparent'
        }`}
        whileHover={!isMobile ? { scale: 1.05, transition: { duration: 0 } } : {}}
        onClick={() => window.location.href = `/${item.title.toLowerCase().replace(/ /g, '-')}`}
      >
        <div className="relative z-10">
          <div className="items-left justify-left mx-auto mb-8 flex">
            <i className={`${item.icon} text-6xl text-secondary_alt`}></i>
          </div>
          <h3 className="text-xl font-semibold mb-2 text-white">{item.title}</h3>
          <p className="text-text-dark">{item.description}</p>
          <motion.a
            href={`/${item.title.toLowerCase().replace(/ /g, '-')}`}
            className="text-accent hover:underline inline-block mt-4 relative overflow-hidden"
          >
            <span className="relative z-10">Learn More</span>
            <motion.div
              className="absolute bottom-0 left-0 w-full h-0.5 bg-accent"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 0 }}
            />
          </motion.a>
        </div>
        {isMobile && isActive && (
          <motion.div
            className="absolute inset-0 rounded-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            style={{ backgroundColor: 'rgba(95, 95, 95, 0.2)' }}
          />
        )}
      </motion.div>
    </motion.div>
  );
};

export default Services;
