import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// FAQ data
const faqs = [
  {
    question: 'What is a deductible?',
    answer:
      "A deductible is an amount that you're responsible for in the event of a loss. This is the amount you pay out-of-pocket, and insurance covers the remainder.",
  },
  {
    question: 'How can I make sure that I have coverage for my valuables?',
    answer:
      'When speaking with your agent to set up your policy, any valuables you are concerned about and our agent can review your policy and recommend changes to ensure your valuables have proper coverage.',
  },
  {
    question: 'Can I customize my insurance policy?',
    answer:
      'Yes, we offer customizable policies to meet your specific needs. Our experts will work with you to tailor the perfect coverage.',
  },
  {
    question: 'Do I need to get a new appraisal to renew or change my coverage?',
    answer:
      'No. You do not need a new home appraisal during the renewal or coverage change process.',
  },
];

// Variants for the container to handle staggered children animations
const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1, // Stagger each child by 0.1 seconds
    },
  },
};

// Variants for each FAQ item
const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

// FAQ Item Component
const FAQItem = ({ faq, index, activeIndex, setActiveIndex }) => {
  return (
    <motion.div
      variants={itemVariants} // Apply item variants
      className="mb-4"
    >
      <div
        className={`cursor-pointer p-4 bg-background border-4 border-accent rounded-lg transition-all duration-300 ${
          activeIndex === index ? 'bg-background-dark' : ''
        }`}
        onClick={() => setActiveIndex(activeIndex === index ? null : index)}
      >
        <div className="flex justify-between items-center">
          <span className="text-lg font-semibold text-white">
            {faq.question}
          </span>
          <motion.svg
            animate={{ rotate: activeIndex === index ? 180 : 0 }}
            transition={{ duration: 0.3 }}
            className="w-6 h-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </motion.svg>
        </div>
        <AnimatePresence>
          {activeIndex === index && (
            <motion.div
              initial={{ opacity: 0, height: 0, marginTop: 0 }}
              animate={{ opacity: 1, height: 'auto', marginTop: '16px' }}
              exit={{ opacity: 0, height: 0, marginTop: 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <p className="text-text-dark">{faq.answer}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

// Main ServicesFAQ Component
const ServicesFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight - 1300, // Adjust the offset as needed
      behavior: 'smooth',
    });
  };

  return (
    <section className="py-20 bg-background-light relative overflow-hidden">
      {/* Background Animations */}
      <motion.div
        className="absolute inset-0 bg-background-light opacity-80"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.8 }}
        transition={{ duration: 1 }}
      />
      <motion.div
        className="absolute inset-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ delay: 0.5, duration: 1 }}
      />

      {/* Content Container */}
      <div className="container mx-auto px-6 relative z-10">
        {/* Section Title */}
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-white"
        >
          Frequently Asked Questions
        </motion.h2>

        {/* FAQ Items with Entrance Animation */}
        <motion.div
          className="max-w-3xl mx-auto"
          variants={containerVariants} // Apply container variants
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }} // Trigger once when 20% is in view
        >
          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              faq={faq}
              index={index}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          ))}
        </motion.div>

        {/* Contact Us Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="mt-12 text-center"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToBottom}
            className="bg-accent hover:bg-accent-dark text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Contact Us
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default ServicesFAQ;
