import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimationControls } from 'framer-motion';

const CarrierScroll = ({ carriers }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef();
  const controls = useAnimationControls();

  useEffect(() => {
    const updateDimensions = () => {
      const mobileView = window.innerWidth <= 768;
      setIsMobile(mobileView);
      setContainerWidth(containerRef.current.offsetWidth);
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (containerWidth > 0) {
      controls.start({
        x: [0, -containerWidth],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: isMobile ? 15 : 25, // Slightly slower for better readability
            ease: "linear"
          }
        }
      });
    }
  }, [controls, containerWidth, isMobile]);

  const renderCarriers = () => {
    const itemWidth = containerWidth / (isMobile ? 3 : carriers.length); // Show 3 items on mobile
    const items = [];
    let currentX = 0;

    while (currentX < containerWidth * 2) {
      carriers.forEach((carrier, index) => {
        items.push(
          <div
            key={`${carrier.name}-${currentX}-${index}`}
            className="flex-shrink-0 flex items-center justify-center"
            style={{ width: itemWidth, height: isMobile ? '100px' : '120px' }}
          >
            <img 
              src={carrier.logo} 
              alt={carrier.name} 
              className="max-w-full max-h-full object-contain filter brightness-0 invert"
            />
          </div>
        );
      });
      currentX += containerWidth;
    }

    return items;
  };

  return (
    <div className="bg-background py-6 overflow-hidden opacity-100">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 text-white">Our Trusted Carriers</h2>
        <div ref={containerRef} className="relative overflow-hidden">
          <motion.div
            animate={controls}
            className="flex items-center"
          >
            {containerWidth > 0 && renderCarriers()}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CarrierScroll;