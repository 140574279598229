import React, { useRef, useEffect, Suspense, useState, useCallback } from 'react';
import { motion, useViewportScroll, useTransform, useSpring } from 'framer-motion';
import { Canvas, useFrame } from '@react-three/fiber';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import * as THREE from 'three';

// Utility hook to detect if the device is mobile
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return isMobile;
};

// Custom Hook to Get Window Height
const useWindowHeight = () => {
  const [height, setHeight] = useState(typeof window !== 'undefined' ? window.innerHeight : 0);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return height;
};

// Easing functions
const easeInOutQuad = (t) => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

const easeOutCubic = (t) => {
  return 1 - Math.pow(1 - t, 3);
};

function scrollDownByPixels(pixels) {
  window.scrollBy({
    top: pixels,
    behavior: 'smooth',
  });
}

// ParticleSphere Component
const ParticleSphere = ({ pointer, triggerExplosion }) => {
  const pointsRef = useRef();
  const particleCount = 2000;

  const isMobile = useIsMobile();

  const [originalPositions] = useState(() => new Float32Array(particleCount * 3));
  const [explodeOffsets] = useState(() => new Float32Array(particleCount * 3));
  const [explodedPositions] = useState(() => new Float32Array(particleCount * 3));
  const [displacementPhases] = useState(() => {
    const phases = new Float32Array(particleCount);
    for (let i = 0; i < particleCount; i++) {
      phases[i] = Math.random() * Math.PI * 2;
    }
    return phases;
  });

  const [phase, setPhase] = useState('idle'); // 'idle', 'exploding', 'lingering', 'reassembling'
  const [progress, setProgress] = useState(0); // Progress within the current phase

  // Initialize particles on the sphere surface
  useEffect(() => {
    const positions = new Float32Array(particleCount * 3);
    const colors = new Float32Array(particleCount * 3);

    const sphereRadius = isMobile ? 5 : 8;

    for (let i = 0; i < particleCount; i++) {
      const phi = Math.acos(2 * Math.random() - 1);
      const theta = 2 * Math.PI * Math.random();

      const x = sphereRadius * Math.sin(phi) * Math.cos(theta);
      const y = sphereRadius * Math.sin(phi) * Math.sin(theta);
      const z = sphereRadius * Math.cos(phi);

      positions.set([x, y, z], i * 3);
      originalPositions.set([x, y, z], i * 3);

      const offsetFactor = Math.random() * 15 + 5;
      const offsetX = (Math.random() - 0.5) * offsetFactor;
      const offsetY = (Math.random() - 0.5) * offsetFactor;
      const offsetZ = (Math.random() - 0.5) * offsetFactor;
      explodeOffsets.set([offsetX, offsetY, offsetZ], i * 3);

      // Modified Color Assignment for Blue Shades
      const baseColor = new THREE.Color('#3495eb');
      const hue = baseColor.getHSL({}).h;
      const saturation = 0.7 + Math.random() * 0.3; // 0.7 to 1.0
      const lightness = 0.4 + Math.random() * 0.3; // 0.4 to 0.7
      const color = new THREE.Color().setHSL(hue, saturation, lightness);

      colors.set([color.r, color.g, color.b], i * 3);
    }

    if (pointsRef.current) {
      pointsRef.current.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
      pointsRef.current.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
    }
  }, [particleCount, originalPositions, explodeOffsets, isMobile]);

  // Handle explosion
  const explode = () => {
    if (phase !== 'idle') return;
    setPhase('exploding');
    setProgress(0);
  };

  // Listen for external explosion trigger
  useEffect(() => {
    if (triggerExplosion) {
      explode();
    }
  }, [triggerExplosion]);

  // Animation parameters
  const explodeDuration = 1.5;
  const lingerDuration = 1;
  const reassembleDuration = 3;
  const transitionDelay = 0.5;

  useFrame((state, delta) => {
    const positions = pointsRef.current.geometry.attributes.position.array;

    switch (phase) {
      case 'exploding':
        // Update progress
        setProgress((prev) => {
          const newProgress = prev + delta / explodeDuration;
          if (newProgress >= 1) {
            for (let i = 0; i < particleCount; i++) {
              const idx = i * 3;
              explodedPositions[idx] = positions[idx];
              explodedPositions[idx + 1] = positions[idx + 1];
              explodedPositions[idx + 2] = positions[idx + 2];
            }
            setProgress(1);
            setPhase('lingering');
            return 1;
          }
          return newProgress;
        });

        // Apply explosion offsets with easing
        const easedProgress = easeOutCubic(progress);
        for (let i = 0; i < particleCount; i++) {
          const idx = i * 3;
          positions[idx] = THREE.MathUtils.lerp(
            originalPositions[idx],
            originalPositions[idx] + explodeOffsets[idx],
            easedProgress
          );
          positions[idx + 1] = THREE.MathUtils.lerp(
            originalPositions[idx + 1],
            originalPositions[idx + 1] + explodeOffsets[idx + 1],
            easedProgress
          );
          positions[idx + 2] = THREE.MathUtils.lerp(
            originalPositions[idx + 2],
            originalPositions[idx + 2] + explodeOffsets[idx + 2],
            easedProgress
          );
        }
        break;

      case 'lingering':
        setProgress((prev) => {
          const newProgress = prev + delta / lingerDuration;
          if (newProgress >= 1) {
            setProgress(1);
            setTimeout(() => {
              setPhase('reassembling');
              setProgress(0);
            }, transitionDelay * 1000);
            return 1;
          }
          return newProgress;
        });
        // Particles remain at maximum displacement
        break;

      case 'reassembling':
        setProgress((prev) => {
          const newProgress = prev + delta / reassembleDuration;
          if (newProgress >= 1) {
            setProgress(1);
            setPhase('idle');
            return 1;
          }
          return newProgress;
        });

        const easedReassemble = easeInOutQuad(progress);
        for (let i = 0; i < particleCount; i++) {
          const idx = i * 3;
          positions[idx] = THREE.MathUtils.lerp(
            explodedPositions[idx],
            originalPositions[idx],
            easedReassemble
          );
          positions[idx + 1] = THREE.MathUtils.lerp(
            explodedPositions[idx + 1],
            originalPositions[idx + 1],
            easedReassemble
          );
          positions[idx + 2] = THREE.MathUtils.lerp(
            explodedPositions[idx + 2],
            originalPositions[idx + 2],
            easedReassemble
          );
        }
        break;

      case 'idle':
      default:
        // Apply dynamic displacement and rotations only when idle
        const time = state.clock.getElapsedTime();
        for (let i = 0; i < particleCount; i++) {
          const idx = i * 3;
          const phaseValue = displacementPhases[i];
          const displacement = 0.02;

          positions[idx] += Math.sin(time * 0.5 + phaseValue) * displacement * delta;
          positions[idx + 1] += Math.cos(time * 0.3 + phaseValue) * displacement * delta;
          positions[idx + 2] += Math.sin(time * 0.4 + phaseValue) * displacement * delta;
        }
        break;
    }

    pointsRef.current.geometry.attributes.position.needsUpdate = true;

    // Auto Rotation
    pointsRef.current.rotation.y += 0.001;

    // Pointer-based Rotation
    const rotationSpeed = 0.002;
    pointsRef.current.rotation.x += pointer.y * rotationSpeed;
    pointsRef.current.rotation.y += pointer.x * rotationSpeed;
  });

  return (
    <points ref={pointsRef} onClick={explode}>
      <bufferGeometry />
      <pointsMaterial
        vertexColors
        size={isMobile ? 0.1 : 0.15}
        sizeAttenuation
        transparent
        opacity={1}
        depthWrite={false}
        blending={THREE.AdditiveBlending}
      />
    </points>
  );
};

// Hero Component
const Hero = () => {
  const { scrollYProgress } = useViewportScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [triggerExplosion, setTriggerExplosion] = useState(false);

  // Custom Hook to Get Window Height
  const windowHeight = useWindowHeight();

  // Calculate bottom offset as 10% of window height (moved up)
  const bottomOffset = windowHeight * 0.2; // Adjusted to 10%

  // Normalize pointer position to range [-1, 1]
  const handlePointerMove = useCallback((event) => {
    const x = (event.clientX / window.innerWidth) * 2 - 1;
    const y = -(event.clientY / window.innerHeight) * 2 + 1;
    setMousePosition({ x, y });
  }, []);

  const handleClick = useCallback(() => {
    setTriggerExplosion(true);
    // Reset trigger after the total duration of the animation
    const totalDuration = (1.5 + 1 + 0.5 + 3) * 1000; // explodeDuration + lingerDuration + transitionDelay + reassembleDuration
    setTimeout(() => setTriggerExplosion(false), totalDuration);
  }, []);

  useEffect(() => {
    window.addEventListener('pointermove', handlePointerMove);
    window.addEventListener('click', handleClick);
    window.addEventListener('touchstart', handleClick);

    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('click', handleClick);
      window.removeEventListener('touchstart', handleClick);
    };
  }, [handlePointerMove, handleClick]);

  const lerpedPointer = useRef({ x: 0, y: 0 });

  useEffect(() => {
    let animationFrameId;

    const lerpPointer = () => {
      lerpedPointer.current.x += (mousePosition.x - lerpedPointer.current.x) * 0.1;
      lerpedPointer.current.y += (mousePosition.y - lerpedPointer.current.y) * 0.1;

      animationFrameId = requestAnimationFrame(lerpPointer);
    };

    lerpPointer();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [mousePosition]);

  const titleY = useSpring(useTransform(scrollYProgress, [0, 0.2], [0, -50]), {
    stiffness: 100,
    damping: 30,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        when: 'beforeChildren',
        staggerChildren: 0.5,
      },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        damping: 15,
        stiffness: 80,
        duration: 0.8,
      },
    },
  };

  const buttonAndScrollVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        damping: 15,
        stiffness: 80,
        duration: 0.8,
        delay: 1, // Delay the appearance of button and scroll suggestion
      },
    },
  };

  return (
    <motion.section
      className="relative h-screen flex items-center justify-center overflow-hidden"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* 3D Canvas Background */}
      <div className="absolute inset-0 z-0">
        <Canvas camera={{ position: [0, 0, 15], fov: 60 }}>
          <color attach="background" args={['#0a0a0a']} />
          <Suspense fallback={null}>
            <ambientLight intensity={1} />
            <pointLight position={[10, 10, 10]} intensity={2} />
            <ParticleSphere pointer={lerpedPointer.current} triggerExplosion={triggerExplosion} />
            {/* **Enhanced Bloom Effect** */}
            <EffectComposer>
              <Bloom
                luminanceThreshold={0}
                luminanceSmoothing={0}
                height={300}
                intensity={1} // Increased intensity for brighter glow
              />
            </EffectComposer>
          </Suspense>
        </Canvas>
      </div>

      {/* Animated Gradient Overlay */}
      <motion.div
        className="absolute inset-0 z-10 bg-background"
        transition={{
          duration: 20,
          ease: 'linear',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
        style={{ opacity: 0 }}
      />

      {/* Main Content */}
      <div className="relative z-20 text-center text-white max-w-3xl mx-auto px-4 md:px-6">
        <motion.h1
          variants={titleVariants}
          className="text-4xl md:text-5xl lg:text-6xl font-bold mb-8 md:mb-10"
          style={{
            y: titleY,
            textShadow:
              '2px 2px 8px rgba(0,0,0,0.8), 0 0 1em rgba(0,0,0,0.4), 0 0 0.2em rgba(0,0,0,0.3)',
          }}
        >
          Your Trusted Insurance Partner
        </motion.h1>
        <motion.div variants={buttonAndScrollVariants}>
          <motion.button
            className="bg-primary text-white px-8 md:px-10 py-3 md:py-4 text-lg md:text-xl rounded-md font-semibold hover:bg-tertiary transition duration-300 relative overflow-hidden group"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              boxShadow:
                '0 4px 6px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 0, 0, 0.4)', // Enhanced shadow for better glow
            }}
            onClick={() => scrollDownByPixels(600)}
          >
            <span className="relative z-10">Get Started</span>
            <motion.div
              className="absolute inset-0 bg-tertiary"
              initial={{ scale: 0, opacity: 0 }}
              whileHover={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.button>
        </motion.div>
      </div>

      {/* Scroll Indicator */}
      <motion.div
        variants={buttonAndScrollVariants}
        className="absolute transform -translate-x-1/2 z-20"
        style={{ bottom: bottomOffset }}
      >
        <motion.div
          className="w-6 h-10 border-2 border-white rounded-full p-1"
          animate={{ y: [0, 10, 0] }}
          transition={{ repeat: Infinity, duration: 1.5, ease: 'easeInOut' }}
        >
          <div className="w-1 h-2 bg-white rounded-full mx-auto" />
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default Hero;
