// ../pages/FireInsurance.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import InsuranceTypePage from './InsuranceTypePage';
import Services from '../components/Services';
import CustomDropdown from '../components/CustomDropdown'; // Import CustomDropdown

const FireInsurance = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    propertyAddress: '',
    propertyType: [],
    yearBuilt: '',
    fireSafetyMeasures: [],
    estimatedPropertyValue: '',
    coverageAmount: '',
    currentFireInsurance: [],
  });

  const [submitMessage, setSubmitMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (fieldName, selectedOptions) => {
    setFormData({ ...formData, [fieldName]: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await axios.post('/api/send-email', {
        formType: 'fire',
        formData: formData
      });

      if (response.data.message === 'Email sent successfully') {
        setSubmitMessage('Your fire insurance quote has been submitted successfully!');
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          propertyAddress: '',
          propertyType: [],
          yearBuilt: '',
          fireSafetyMeasures: [],
          estimatedPropertyValue: '',
          coverageAmount: '',
          currentFireInsurance: [],
        });
      } else {
        setSubmitMessage('Failed to submit your quote. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred while submitting your quote.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageProps = {
    title: "Fire Insurance Solutions",
    description:
      "At RPIS, we believe every homeowner deserves protection from the risks of fire. We work with top insurance carriers to find the best fire insurance coverage for your property.",
    customDescription:
      "We provide fire insurance plans designed to meet your unique needs and budget. Whether you're insuring a home or business, we’ll help you find the right policy to safeguard your assets.",
    benefits: [
      "Access to leading carriers for the best fire insurance rates.",
      "Flexible coverage options tailored to your specific fire risks.",
      "Affordable premiums with various payment methods.",
      "Expert guidance to help you choose the ideal policy.",
      "Full support during the claims process.",
    ],
    coverageItems: [
      "Residential and Commercial Fire Insurance",
      "Total Property Loss Protection",
      "Personal Belongings Coverage",
      "Wildfire-Specific Insurance Plans",
      "Rebuilding and Repair Coverage",
    ],
    imageSrc: "/4Q.jpg",
    carriers: [
      { name: "ProGressive", logo: "/pg.png" },
      { name: "StateFarm", logo: "/sf.png" },
      { name: "Geico", logo: "/gc.png" },
      { name: "AllState", logo: "/as.png" },
      { name: "USAA", logo: "/ua.png" },
    ],
  };

  const formFields = [
    // Personal Information
    { name: 'fullName', label: 'Full Name', type: 'text', required: true },
    { name: 'email', label: 'Email Address', type: 'email', required: true },
    { name: 'phone', label: 'Phone Number', type: 'tel', required: true },
    
    // Property Details
    { name: 'propertyAddress', label: 'Property Address', type: 'text', required: true },
    { name: 'propertyType', label: 'Property Type', type: 'custom-select', options: ['Home', 'Apartment', 'Commercial'], required: true },
    { name: 'yearBuilt', label: 'Year Built', type: 'number', required: true },
    { name: 'fireSafetyMeasures', label: 'Fire Safety Measures', type: 'custom-select', options: ['Smoke Detectors', 'Fire Alarms', 'Sprinklers', 'None'], required: true },
    
    // Insurance Information
    { name: 'estimatedPropertyValue', label: 'Estimated Property Value', type: 'number', required: true },
    { name: 'coverageAmount', label: 'Coverage Amount', type: 'number', required: true },
    { name: 'currentFireInsurance', label: 'Do You Currently Have Fire Insurance?', type: 'custom-select', options: ['Yes', 'No'], required: true },
  ];

  // Define options for custom-select fields
  const propertyTypeOptions = [
    { value: 'Home', label: 'Home' },
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Commercial', label: 'Commercial' },
  ];

  const fireSafetyMeasuresOptions = [
    { value: 'Smoke Detectors', label: 'Smoke Detectors' },
    { value: 'Fire Alarms', label: 'Fire Alarms' },
    { value: 'Sprinklers', label: 'Sprinklers' },
    { value: 'None', label: 'None' },
  ];

  const currentFireInsuranceOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  return (
    <div>
      <InsuranceTypePage {...pageProps} />
      <section className="py-20 bg-background-dark">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-center mb-12 text-white"
          >
            Get Your Fire Insurance Quote
          </motion.h2>
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onSubmit={handleSubmit}
            className="max-w-3xl mx-auto bg-background-light p-8 rounded-lg shadow-md border-4 border-accent"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {formFields.map((field) => (
                field.type !== 'custom-select' ? (
                  <motion.div
                    key={field.name}
                    whileHover={field.type !== 'textarea' ? { scale: 1.05 } : {}}
                    whileTap={field.type !== 'textarea' ? { scale: 0.95 } : {}}
                    className="flex flex-col"
                  >
                    <label htmlFor={field.name} className="block text-text-dark mb-2">
                      {field.label}
                      {field.required && <span className="text-red-500">*</span>}
                    </label>
                    {field.type === 'textarea' ? (
                      <textarea
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md"
                        rows="3"
                      />
                    ) : (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md"
                      />
                    )}
                  </motion.div>
                ) : (
                  <CustomDropdown
                    key={field.name}
                    label={field.label}
                    options={
                      field.name === 'propertyType'
                        ? propertyTypeOptions
                        : field.name === 'fireSafetyMeasures'
                        ? fireSafetyMeasuresOptions
                        : field.name === 'currentFireInsurance'
                        ? currentFireInsuranceOptions
                        : []
                    }
                    selected={formData[field.name]}
                    onSelect={(selected) => handleSelect(field.name, selected)}
                    isMultiSelect={field.name === 'fireSafetyMeasures'} // Enable multi-select for Fire Safety Measures
                    placeholder={`Select ${field.label}`}
                  />
                )
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="mt-8 w-full bg-accent text-white font-bold py-2 px-6 rounded-md hover:bg-accent-light transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Get Your Quote'}
            </motion.button>
            {submitMessage && (
              <p className="mt-4 text-center text-white">{submitMessage}</p>
            )}
          </motion.form>
        </div>
      </section>
      <Services header="Types of Insurance" />
    </div>
  );
};

export default FireInsurance;
