import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import CarrierScroll from '../components/CarrierScroll';

const InsuranceTypePage = ({ title, description, customDescription, benefits, coverageItems, imageSrc, carriers }) => {
  const quoteFormRef = useRef(null);

  const scrollToQuoteForm = () => {
    quoteFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <section className="relative min-h-screen bg-background-light flex items-center justify-center mx-auto">
        <div className="container mx-auto px-6 py-20 flex flex-col items-center">
          <motion.div 
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full max-w-2xl text-left"
          >
            <h1 className="text-4xl md:text-5xl font-bold text-accent mb-6">{title}</h1>
            <p className="text-text-light text-lg mb-8">{description}</p>
            <h2 className="text-3xl font-bold text-white mb-6">Customized Insurance Just for You</h2>
            <p className="text-text-light text-lg mb-8">{customDescription}</p>
            
            <div className="bg-primary rounded-lg p-6 mb-8">
              <h3 className="text-2xl font-bold text-white mb-4">Key Benefits:</h3>
              <ul className="list-disc list-inside text-text-light mb-6">
                {benefits.map((benefit, index) => (
                  <li key={index} className="mb-2">{benefit}</li>
                ))}
              </ul>
              <h3 className="text-2xl font-bold text-white mb-4">Coverage Includes:</h3>
              {coverageItems.map((item, index) => (
                <div key={index} className="flex items-center mb-4 last:mb-0">
                  <svg className="w-6 h-6 text-white mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-text-light">{item}</span>
                </div>
              ))}
            </div>
            
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary text-white px-8 py-3 rounded-md font-semibold text-lg hover:bg-accent-light transition duration-300"
              onClick={scrollToQuoteForm}
            >
              Get a Quote
            </motion.button>
          </motion.div>
        </div>
      </section>
      <CarrierScroll carriers={carriers} />
      <div ref={quoteFormRef} id="quote-form" className="form-section">
        {/* The actual form content will be rendered here by the parent component */}
      </div>
    </>
  );
};

export default InsuranceTypePage;