import React from 'react';
import { motion } from 'framer-motion';

const contactMethods = [
  { icon: 'fi fi-rr-phone-flip', title: 'Phone', content: '(123) 456-7890', action: 'Call now' },
  { icon: 'fi fi-rr-envelope', title: 'Email', content: 'info@RPIS.com', action: 'Send an email' },
  { icon: 'fi fi-rs-city', title: 'Office', content: '123 Insurance St, City, State 12345', action: 'Get directions' },
];

const ContactInfo = () => {
  return (
    <section className="py-20 bg-background-light">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-accent"
        >
          Get in Touch
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {contactMethods.map((method, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
              className="bg-primary p-6 rounded-lg shadow-md text-center"
            >
              <div className="text-4xl mb-4"><i className={method.icon + ' text-white text-6xl'}></i></div>
              <h3 className="text-xl font-semibold mb-2 text-white">{method.title}</h3>
              <p className="text-white mb-4">{method.content}</p>
              <a
                href="#"
                className="inline-block bg-accent text-white px-4 py-2 rounded-md font-semibold hover:bg-accent-light transition duration-300"
              >
                {method.action}
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;