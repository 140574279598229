// ../pages/ServicesContact.js
import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import CustomDropdown from '../CustomDropdown';

const ServicesContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    services: [],
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const services = [
    { value: 'health', label: 'Health Insurance' },
    { value: 'life', label: 'Life Insurance' },
    { value: 'auto', label: 'Auto Insurance' },
    { value: 'property', label: 'Property Insurance' },
    { value: 'business', label: 'Business Insurance' },
    { value: 'fire', label: 'Fire Insurance' },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleServiceSelect = (selectedValues) => {
    setFormData({ ...formData, services: selectedValues });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await axios.post('/api/send-email', {
        formType: 'contact',
        formData: formData
      });

      if (response.data.message === 'Email sent successfully') {
        setSubmitMessage('Thank you for your message. We will get back to you soon!');
        setFormData({ name: '', email: '', phone: '', services: [], message: '' });
      } else {
        setSubmitMessage('Failed to send your message. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmitMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="py-20 bg-background-dark">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-white"
        >
          Get in Touch
        </motion.h2>
        <div className="max-w-3xl mx-auto bg-background-light p-8 rounded-lg shadow-md border-4 border-accent">
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="name" className="block text-text-dark mb-2">Name</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="email" className="block text-text-dark mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="phone" className="block text-text-dark mb-2">Phone</label>
              <input
                type="tel"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md"
                required
              />
            </div>

            <CustomDropdown
              label="Service Interested In"
              options={services}
              selected={formData.services}
              onSelect={handleServiceSelect}
              isMultiSelect={true}
              placeholder="Select services"
            />

            <div className="mb-6">
              <label htmlFor="message" className="block text-text-dark mb-2">Message</label>
              <textarea
                id="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md"
                required
              ></textarea>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="bg-accent text-white px-6 py-2 rounded-md font-semibold hover:bg-accent-light transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </motion.button>
            {submitMessage && (
              <p className="mt-4 text-center text-white">{submitMessage}</p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default ServicesContact;
