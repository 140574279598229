// Header.js

import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// Configuration object for header links
const headerLinks = [
  { text: 'Home', path: '/' },
  { text: 'About', path: '/about' },
  { 
    text: 'Types of Insurance', 
    path: '/services',
    dropdown: [
      { text: 'Auto Insurance', path: '/auto-insurance' },
      { text: 'Health Insurance', path: '/health-insurance' },
      { text: 'Life Insurance', path: '/life-insurance' },
      { text: 'Property Insurance', path: '/property-insurance' },
      { text: 'Business Insurance', path: '/business-insurance' },
      { text: 'Fire Insurance', path: '/fire-insurance' }
    ]
  },
  { text: 'Contact', path: '/contact' },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const toggleButtonRef = useRef(null);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsOpen(false); // Close menu if switching to desktop
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close menu function
  const closeMenu = (path) => {
    setIsOpen(false);
    if (path) {
      navigate(path);
    }
  };

  // Animation Variants
  const headerVariants = {
    hidden: { 
      opacity: 0, 
      y: -50,
    },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.6, -0.05, 0.01, 0.99],
        when: "beforeChildren",
        staggerChildren: 0.1,
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  const dropdownVariants = {
    hidden: { 
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.2
      }
    },
    visible: { 
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    }
  };

  // Click Outside and Scroll Handlers
  useEffect(() => {
    if (!isOpen || !isMobile) return;

    // Function to handle clicks outside the menu and toggle button
    const handleClickOutside = (event) => {
      if (
        menuRef.current && 
        !menuRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Function to handle scroll events
    const handleScroll = () => {
      setIsOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll, { passive: true });

    // Cleanup the event listeners
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen, isMobile]);

  return (
    <motion.header
      initial="hidden"
      animate="visible"
      variants={headerVariants}
      className="bg-background-dark shadow-md fixed top-0 left-0 right-0 z-50"
    >
      <nav className="container mx-auto px-6 py-3 relative">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <motion.div variants={itemVariants}>
            <Link to="/" className="flex items-center text-2xl font-bold text-white" onClick={() => closeMenu('/')}>
              <svg className="w-8 h-8 mr-2" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 2a8 8 0 100 16 8 8 0 000-16zm0 14a6 6 0 110-12 6 6 0 010 12z" />
              </svg>
              RPIS
            </Link>
          </motion.div>

          {/* Desktop Navigation Links */}
          <div className="hidden md:flex space-x-4">
            {headerLinks.map((link) => (
              <motion.div key={link.text} variants={itemVariants} className="relative">
                {link.dropdown ? (
                  <div
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                  >
                    <Link 
                      to={link.path}
                      className="text-text hover:text-quaternary cursor-pointer"
                    >
                      {link.text}
                    </Link>
                    <AnimatePresence>
                      {isDropdownOpen && (
                        <motion.div
                          initial="hidden"
                          animate="visible"
                          exit="hidden"
                          variants={dropdownVariants}
                          className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                        >
                          <div className="py-1">
                            {link.dropdown.map((subItem) => (
                              <Link
                                key={subItem.text}
                                to={subItem.path}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                onClick={() => closeMenu(subItem.path)}
                              >
                                {subItem.text}
                              </Link>
                            ))}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <Link 
                    to={link.path}
                    className="text-text hover:text-quaternary" 
                    onClick={() => closeMenu(link.path)}
                  >
                    {link.text}
                  </Link>
                )}
              </motion.div>
            ))}
          </div>

          {/* Desktop "Get a Quote" Button */}
          <motion.div variants={itemVariants}>
            <button 
              className="bg-tertiary text-white px-4 py-2 rounded-md hidden md:block hover:bg-opacity-90 transition duration-300"
              onClick={() => closeMenu('/contact')}
            >
              Get a quote
            </button>
          </motion.div>

          {/* Mobile Menu Toggle Button */}
          <motion.button
            ref={toggleButtonRef}
            variants={itemVariants}
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden focus:outline-none text-text"
            aria-label="Toggle menu"
            aria-expanded={isOpen}
          >
            {isOpen ? (
              // Close Icon
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              // Hamburger Icon
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </motion.button>
        </div>

        {/* Mobile Navigation Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              ref={menuRef}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="md:hidden mt-4 overflow-hidden"
            >
              {headerLinks.map((link) => (
                <motion.div
                  key={link.text}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  <Link 
                    to={link.path}
                    className="block py-2 text-text hover:text-quaternary px-4" 
                    onClick={() => closeMenu(link.path)}
                  >
                    {link.text}
                  </Link>
                  {link.dropdown && (
                    <div className="pl-6">
                      {link.dropdown.map((subItem) => (
                        <Link
                          key={subItem.text}
                          to={subItem.path}
                          className="block py-2 text-sm text-text hover:text-quaternary"
                          onClick={() => closeMenu(subItem.path)}
                        >
                          {subItem.text}
                        </Link>
                      ))}
                    </div>
                  )}
                </motion.div>
              ))}

              {/* Mobile "Get a Quote" Button */}
              <motion.div variants={itemVariants} className="px-4 py-2">
                <button 
                  className="bg-tertiary text-white px-4 py-2 rounded-md w-full hover:bg-opacity-90 transition duration-300"
                  onClick={() => closeMenu('/contact')}
                >
                  Get a quote
                </button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </motion.header>
  );
};

export default Header;
