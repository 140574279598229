import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-background-dark text-text py-12">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div>
            <h3 className="text-xl font-bold mb-4 text-quaternary">RPIS</h3>
            <p className="text-tertiary">Your Trusted Insurance Partner</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-quaternary">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-quaternary">Home</Link></li>
              <li><Link to="/about" className="hover:text-quaternary">About</Link></li>
              <li><Link to="/services" className="hover:text-quaternary">Types of Insurance</Link></li>
              <li><Link to="/contact" className="hover:text-quaternary">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-quaternary">Types of Insurance</h4>
            <ul className="space-y-2">
              <li><Link to="/property-insurance" className="hover:text-quaternary">Property Insurance</Link></li>
              <li><Link to="/life-insurance" className="hover:text-quaternary">Life Insurance</Link></li>
              <li><Link to="/auto-insurance" className="hover:text-quaternary">Auto Insurance</Link></li>
              <li><Link to="/business-insurance" className="hover:text-quaternary">Business Insurance</Link></li>
              <li><Link to="/health-insurance" className="hover:text-quaternary">Health Insurance</Link></li>
              <li><Link to="/fire-insurance" className="hover:text-quaternary">Fire Insurance</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4 text-quaternary">Contact Us</h4>
            <p className="text-tertiary mb-2">123 Sample Street, City, Country</p>
            <p className="text-tertiary mb-2">Phone: (123) 456-7890</p>
            <p className="text-tertiary">Email: info@RPIS.com</p>
          </div>
        </div>
        <div className="border-t border-white pt-8 text-center">
          <p className="text-tertiary">&copy; 2024 RPIS. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;