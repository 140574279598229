import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const teamMembers = [
  { name: 'John Doe', role: 'CEO & Founder', image: '/api/placeholder/300/300' },
  { name: 'Jane Smith', role: 'Chief Operating Officer', image: '/api/placeholder/300/300' },
  { name: 'Mike Johnson', role: 'Chief Financial Officer', image: '/api/placeholder/300/300' },
];

const OurTeam = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <section ref={ref} className="py-20 bg-background-light">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-accent-light"
        >
          Leadership Team
        </motion.h2>
        <div className="flex justify-center flex-wrap -mx-4 w-full">
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -50 }}
              transition={{ delay: index * 0.1, duration: 0.5 }}
              className="text-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8"
            >
              <h3 className="text-xl font-semibold mb-2 text-white">{member.name}</h3>
              <p className="text-text-light">{member.role}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;