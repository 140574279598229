import React, { useRef, useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

const steps = [
  { title: 'Consultation', description: 'Discuss your insurance needs with our experts.' },
  { title: 'Customization', description: 'We tailor a policy to fit your specific requirements.' },
  { title: 'Quote', description: 'Receive a competitive quote for your customized policy.' },
  { title: 'Activation', description: 'Activate your policy and enjoy peace of mind.' },
];

const generateDesktopPath = (stepSpacingDesktop) => {
  let path = `M150 50`;
  for (let i = 0; i < steps.length - 1; i++) {
    const currentY = 50 + i * stepSpacingDesktop;
    const nextY = 50 + (i + 1) * stepSpacingDesktop;
    const controlX = i % 2 === 0 ? 180 : 120; // Alternate control points for consistent amplitude
    const controlY = currentY + stepSpacingDesktop * 0.5;
    path += ` Q${controlX} ${controlY},150 ${nextY}`;
  }
  return path;
};

const Circle = ({ index, scrollYProgress, isMobile, stepSpacingDesktop, stepSpacingMobile }) => {
  const stepHeight = 1 / steps.length;
  const stepSpacing = isMobile ? stepSpacingMobile : stepSpacingDesktop;

  // Adjusted animation range to slow down the animation
  const start = isMobile
    ? index * stepHeight - stepHeight * 1.2 // Increased from 0.8 to 1.2
    : Math.max(0, index * stepHeight - stepHeight * 0.6); // Increased from 0.3 to 0.6
  const end = isMobile
    ? index * stepHeight + stepHeight * 2.4 // Increased from 1.8 to 2.4
    : index * stepHeight + stepHeight * 1.4; // Increased from 0.7 to 1.4

  const opacity = useTransform(scrollYProgress, [start, end], [0.3, 1]);

  const cx = isMobile ? 40 : 150; // Left position for circles on mobile and desktop
  const cy = 50 + index * stepSpacing;

  return (
    <motion.circle
      cx={cx}
      cy={cy}
      r="10"
      fill="#ffffff"
      style={{ opacity }}
    />
  );
};

const Box = ({ index, step, scrollYProgress, isMobile, stepSpacingDesktop, stepSpacingMobile }) => {
  const stepHeight = 1 / steps.length;
  const stepSpacing = isMobile ? stepSpacingMobile : stepSpacingDesktop;

  // Adjusted animation range to slow down the animation
  const start = isMobile
    ? index * stepHeight - stepHeight * 1.2 // Increased from 0.8 to 1.2
    : Math.max(0, index * stepHeight - stepHeight * 0.6); // Increased from 0.3 to 0.6
  const end = isMobile
    ? index * stepHeight + stepHeight * 2.4 // Increased from 1.8 to 2.4
    : index * stepHeight + stepHeight * 1.4; // Increased from 0.7 to 1.4

  const opacity = useTransform(scrollYProgress, [start, end], [0.3, 1]);
  const scale = useTransform(scrollYProgress, [start, end], [0.9, 1]);

  const topPosition = 50 + index * stepSpacing;

  // On mobile, all boxes are on the right with a fixed left position
  const leftPosition = isMobile ? '100px' : (index % 2 === 0 ? '-200px' : '240px');

  return (
    <motion.div
      style={{
        opacity,
        scale,
        position: 'absolute',
        top: `${topPosition}px`,
        left: leftPosition,
      }}
      className="w-64 p-4 bg-white rounded-lg shadow-lg"
    >
      <h3 className="text-xl font-semibold mb-2 text-black">{step.title}</h3>
      <p className="text-text-black">{step.description}</p>
    </motion.div>
  );
};

const ConnectingLine = ({ index, scrollYProgress, isMobile, stepSpacingDesktop, stepSpacingMobile }) => {
  const stepHeight = 1 / steps.length;
  const stepSpacing = isMobile ? stepSpacingMobile : stepSpacingDesktop;

  // Adjusted animation range to slow down the animation
  const start = isMobile
    ? index * stepHeight - stepHeight * 1.2 // Increased from 0.8 to 1.2
    : Math.max(0, index * stepHeight - stepHeight * 0.6); // Increased from 0.3 to 0.6
  const end = isMobile
    ? index * stepHeight + stepHeight * 2.4 // Increased from 1.8 to 2.4
    : index * stepHeight + stepHeight * 1.4; // Increased from 0.7 to 1.4

  const opacity = useTransform(scrollYProgress, [start, end], [0, 1]);

  if (isMobile) {
    // Straight thicker line to the right on mobile
    const x1 = 40; // circle x
    const y1 = 50 + index * stepSpacing;
    const x2 = 100; // box left position
    const y2 = y1;

    return (
      <motion.line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke="#ffffff"
        strokeWidth="4"
        style={{ opacity }}
      />
    );
  } else {
    // Desktop: connect to left or right with thinner line
    const isLeft = index % 2 === 0;

    // Starting point (circle position)
    const x1 = 150;
    const y1 = 50 + index * stepSpacingDesktop;

    // Ending point (edge of the box)
    const x2 = isLeft ? 50 : 250; // Adjust based on box position
    const y2 = y1; // Same y position

    return (
      <motion.line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke="#ffffff"
        strokeWidth="2"
        style={{ opacity }}
      />
    );
  }
};

const ServicesProcess = () => {
  const containerRef = useRef(null);
  const pathRef = useRef(null);
  const [pathLength, setPathLength] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // Define step spacing based on device type
  const stepSpacingDesktop = 200; // Original step spacing for desktop
  const stepSpacingMobile = 300;  // Increased step spacing for mobile

  // Detect if the device is mobile
  useEffect(() => {
    const checkIsMobile = () => {
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth <= 768);
      }
    };

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  // Calculate the total length of the path for animation
  useEffect(() => {
    if (pathRef.current) {
      setPathLength(pathRef.current.getTotalLength());
    }
  }, [isMobile]);

  // Adjusted useScroll offset to control animation speed
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });

  // Map the scroll progress to strokeDashoffset uniformly
  const strokeDashoffset = useTransform(
    scrollYProgress,
    [0, 1],
    [pathLength, 0]
  );

  // Generate the desktop path dynamically
  const desktopPath = generateDesktopPath(stepSpacingDesktop);

  return (
    <section ref={containerRef} className="py-20 bg-background-light pb-0">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }} // Increased duration from 0.5 to 0.7
          viewport={{ once: true }}
          className="text-3xl font-bold text-center mb-12 text-accent"
        >
          Our Simple Process
        </motion.h2>
        <div className={`flex ${isMobile ? 'justify-start' : 'justify-center'}`}>
          <div
            className="relative"
            style={{
              width: isMobile ? '150px' : '300px',
              height: isMobile ? `${steps.length * stepSpacingMobile}px` : `${steps.length * stepSpacingDesktop}px`,
            }}
          >
            {/* SVG for the vertical line, circles, and connecting lines */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox={`0 0 ${isMobile ? 150 : 300} ${isMobile ? steps.length * stepSpacingMobile : steps.length * stepSpacingDesktop}`}
              style={{
                position: 'absolute',
                top: 0,
                left: '0',
                width: '100%',
                height: '100%',
              }}
            >
              {!isMobile ? (
                <motion.path
                  d={desktopPath}
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="4"
                  strokeDasharray={pathLength}
                  style={{ strokeDashoffset }}
                  ref={pathRef}
                />
              ) : (
                <motion.line
                  x1={40}
                  y1={0}
                  x2={40}
                  y2={steps.length * stepSpacingMobile}
                  stroke="#ffffff"
                  strokeWidth="4"
                  strokeDasharray={pathLength}
                  style={{ strokeDashoffset }}
                  ref={pathRef}
                />
              )}
              {steps.map((_, index) => (
                <React.Fragment key={index}>
                  <Circle
                    index={index}
                    scrollYProgress={scrollYProgress}
                    isMobile={isMobile}
                    stepSpacingDesktop={stepSpacingDesktop}
                    stepSpacingMobile={stepSpacingMobile}
                  />
                  <ConnectingLine
                    index={index}
                    scrollYProgress={scrollYProgress}
                    isMobile={isMobile}
                    stepSpacingDesktop={stepSpacingDesktop}
                    stepSpacingMobile={stepSpacingMobile}
                  />
                </React.Fragment>
              ))}
            </svg>

            {/* Boxes */}
            {steps.map((step, index) => (
              <Box
                key={index}
                index={index}
                step={step}
                scrollYProgress={scrollYProgress}
                isMobile={isMobile}
                stepSpacingDesktop={stepSpacingDesktop}
                stepSpacingMobile={stepSpacingMobile}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesProcess;
