// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion, useScroll, useSpring } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import HealthInsurance from './pages/HealthInsurance';
import LifeInsurance from './pages/LifeInsurance';
import AutoInsurance from './pages/AutoInsurance';
import HomeInsurance from './pages/HomeInsurance';
import BusinessInsurance from './pages/BusinessInsurance';
import TravelInsurance from './pages/TravelInsurance';
import { ServerIcon } from 'lucide-react';
import ScrollToTop from './components/ScrollToTop';

// Optional: Create a separate ProgressBar component for better organization
const ProgressBar = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <motion.div
      className="fixed top-0 left-0 right-0 h-2 bg-accent origin-left z-60" // Increased z-index to 60
      style={{ scaleX }}
    />
  );
};

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        {/* Progress Bar */}
        <ProgressBar />

        <Header />
        <ScrollToTop />

        <main className="flex-grow mt-16"> {/* Added top margin to prevent content from hiding behind the fixed Header */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/health-insurance" element={<HealthInsurance />} />
            <Route path="/life-insurance" element={<LifeInsurance />} />
            <Route path="/auto-insurance" element={<AutoInsurance />} />
            <Route path="/home-insurance" element={<HomeInsurance />} />
            <Route path="/business-insurance" element={<BusinessInsurance />} />
            <Route path="/travel-insurance" element={<TravelInsurance />} />
            <Route path="/types-of-insurance" element={<Services />} />
            <Route path="/fire-insurance" element={<TravelInsurance />} />
            <Route path="/property-insurance" element={<HomeInsurance />} />
          </Routes>
        </main>
              {/* Scroll to Top Button */}
      <motion.div
        className="fixed bottom-4 right-4 z-50"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        <motion.button
          className="bg-accent text-white px-4 py-2 rounded-full shadow-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          <i className="fi fi-rr-arrow-up"></i>
        </motion.button>
      </motion.div>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
