// ../pages/LifeInsurance.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import InsuranceTypePage from './InsuranceTypePage';
import Services from '../components/Services';
import CustomDropdown from '../components/CustomDropdown'; // Import CustomDropdown

const LifeInsurance = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    dateOfBirth: '',
    gender: [],
    tobaccoUse: [],
    healthStatus: [],
    coverageAmount: '',
    beneficiaryName: '',
    termLength: [],
    currentProvider: '',
    employed: [],
  });

  const [submitMessage, setSubmitMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (fieldName, selectedOptions) => {
    setFormData({ ...formData, [fieldName]: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await axios.post('/api/send-email', {
        formType: 'life',
        formData: formData
      });

      if (response.data.message === 'Email sent successfully') {
        setSubmitMessage('Your life insurance quote has been submitted successfully!');
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          dateOfBirth: '',
          gender: [],
          tobaccoUse: [],
          healthStatus: [],
          coverageAmount: '',
          beneficiaryName: '',
          termLength: [],
          currentProvider: '',
          employed: [],
        });
      } else {
        setSubmitMessage('Failed to submit your quote. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred while submitting your quote.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageProps = {
    title: "Life Insurance Solutions",
    description:
      "At RPIS, we prioritize protecting your family’s financial future. As a brokerage, we partner with multiple leading carriers to ensure you receive the best life insurance coverage at the most competitive rates, all tailored to your unique needs.",
    customDescription:
      "Our life insurance solutions are carefully crafted to align with your family’s financial goals. We offer a variety of flexible and affordable plans, ensuring that as your life evolves, your coverage does too.",
    benefits: [
      "Access to a variety of carriers, allowing us to offer the best possible rates.",
      "Tailored coverage options that evolve with your life stages and needs.",
      "Affordable premiums with flexible payment schedules.",
      "Expert advice to help you select the right policy for your family.",
      "A respectful and efficient claims process during life’s challenging moments.",
    ],
    coverageItems: [
      "Term life insurance",
      "Whole life insurance",
      "Universal life insurance",
      "Accidental death and dismemberment coverage",
    ],
    imageSrc: "/4Q.jpg",
    carriers: [
      { name: "ProGressive", logo: "/pg.png" },
      { name: "StateFarm", logo: "/sf.png" },
      { name: "Geico", logo: "/gc.png" },
      { name: "AllState", logo: "/as.png" },
      { name: "USAA", logo: "/ua.png" },
    ],
  };

  const formFields = [
    { name: 'fullName', label: 'Full Name', type: 'text', required: true },
    { name: 'email', label: 'Email Address', type: 'email', required: true },
    { name: 'phone', label: 'Phone Number', type: 'tel', required: true },
    { name: 'dateOfBirth', label: 'Date of Birth', type: 'date', required: true },
    { name: 'gender', label: 'Gender', type: 'custom-select', options: ['Male', 'Female', 'Other'], required: false },
    { name: 'tobaccoUse', label: 'Tobacco Use', type: 'custom-select', options: ['Yes', 'No'], required: true },
    { name: 'healthStatus', label: 'Health Status', type: 'custom-select', options: ['Excellent', 'Good', 'Fair', 'Poor'], required: false },
    { name: 'coverageAmount', label: 'Coverage Amount Desired', type: 'number', required: true },
    { name: 'beneficiaryName', label: 'Beneficiary Name', type: 'text', required: false },
    { name: 'termLength', label: 'Term Length', type: 'custom-select', options: ['10 years', '20 years', '30 years'], required: true },
    { name: 'currentProvider', label: 'Current Life Insurance Provider', type: 'text', required: false },
    { name: 'employed', label: 'Are You Employed?', type: 'custom-select', options: ['Yes', 'No'], required: true },
  ];

  const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
  ];

  const tobaccoUseOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const healthStatusOptions = [
    { value: 'Excellent', label: 'Excellent' },
    { value: 'Good', label: 'Good' },
    { value: 'Fair', label: 'Fair' },
    { value: 'Poor', label: 'Poor' },
  ];

  const termLengthOptions = [
    { value: '10 years', label: '10 years' },
    { value: '20 years', label: '20 years' },
    { value: '30 years', label: '30 years' },
  ];

  const employedOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  // Remaining code goes here
  const coverageTypeOptions = [
    { value: 'Individual', label: 'Individual' },
    { value: 'Family', label: 'Family' },
    { value: 'Group', label: 'Group' },
  ];

  const preExistingConditionsOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  return (
    <div>
      <InsuranceTypePage {...pageProps} />
      <section className="py-20 bg-background-dark">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-center mb-12 text-white"
          >
            Get Your Life Insurance Quote
          </motion.h2>
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onSubmit={handleSubmit}
            className="max-w-3xl mx-auto bg-background-light p-8 rounded-lg shadow-md border-4 border-accent"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {formFields.map((field) => (
                field.type !== 'custom-select' ? (
                  <motion.div
                    key={field.name}
                    whileHover={field.type !== 'textarea' ? { scale: 1.05 } : {}}
                    whileTap={field.type !== 'textarea' ? { scale: 0.95 } : {}}
                    className="flex flex-col"
                  >
                    <label htmlFor={field.name} className="block text-text-dark mb-2">
                      {field.label}
                      {field.required && <span className="text-red-500">*</span>}
                    </label>
                    {field.type === 'textarea' ? (
                      <textarea
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                        rows="3"
                      />
                    ) : (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                      />
                    )}
                  </motion.div>
                ) : (
                  <CustomDropdown
                    key={field.name}
                    label={field.label}
                    options={
                      field.name === 'gender'
                        ? genderOptions
                        : field.name === 'coverageType'
                        ? coverageTypeOptions
                        : field.name === 'preExistingConditions'
                        ? preExistingConditionsOptions
                        : field.name === 'tobaccoUse'
                        ? tobaccoUseOptions
                        : field.name === 'termLength'
                        ? termLengthOptions
                        : field.name === 'employed'
                        ? employedOptions
                        : ['Yes', 'No']
                        ? healthStatusOptions
                        : ['Excellent', 'Good', 'Fair', 'Poor']
                    }
                    selected={formData[field.name]}
                    onSelect={(selected) => handleSelect(field.name, selected)}
                    isMultiSelect={false} // Set to true if multi-select is needed
                    placeholder={`Select ${field.label}`}
                  />
                )
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="mt-8 w-full bg-accent text-white font-bold py-2 px-6 rounded-md hover:bg-accent-light transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Get Your Quote'}
            </motion.button>
            {submitMessage && (
              <p className="mt-4 text-center text-white">{submitMessage}</p>
            )}
          </motion.form>
        </div>
      </section>
      <Services header="Types of Insurance" />
    </div>
  );
};

export default LifeInsurance;
