import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const PreFooter = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <section ref={ref} className="py-20 bg-background-lightest">
      <div className="container mx-auto px-6 text-center">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          <h2 className="text-3xl font-bold mb-6 text-text-light">
            Ready to secure your future?
          </h2>
          <p className="text-xl mb-8 text-text">
            Get in touch with our experts today and find the perfect coverage for your needs.
          </p>
          <motion.button
            className="bg-secondary_alt text-white px-8 py-3 rounded-md font-semibold text-lg hover:bg-accent-light transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => window.location.href = '/contact'}
          >
            Contact Us Now
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default PreFooter;