// ../pages/BusinessInsurance.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import InsuranceTypePage from './InsuranceTypePage';
import Services from '../components/Services';
import CustomDropdown from '../components/CustomDropdown'; // Import CustomDropdown

const BusinessInsurance = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    contactName: '',
    email: '',
    phone: '',
    address: '',
    businessType: '',
    yearsInBusiness: '',
    employees: '',
    annualRevenue: '',
    startDate: '',
    coverageType: [],
    currentProvider: '',
  });

  const [submitMessage, setSubmitMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelect = (fieldName, selectedOptions) => {
    setFormData({ ...formData, [fieldName]: selectedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await axios.post('/api/send-email', {
        formType: 'business',
        formData: formData
      });

      if (response.data.message === 'Email sent successfully') {
        setSubmitMessage('Your business insurance quote has been submitted successfully!');
        setFormData({
          businessName: '',
          contactName: '',
          email: '',
          phone: '',
          address: '',
          businessType: '',
          yearsInBusiness: '',
          employees: '',
          annualRevenue: '',
          startDate: '',
          coverageType: [],
          currentProvider: '',
        });
      } else {
        setSubmitMessage('Failed to submit your quote. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred while submitting your quote.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const pageProps = {
    title: "Business Insurance Solutions",
    description:
      "As a brokerage appointed by top carriers, RPIS ensures your business is protected at the best possible rates. We offer a range of business insurance options designed to safeguard your assets, employees, and operations.",
    customDescription:
      "Our business insurance policies are customized to fit the specific needs of your business, whether you're a small startup or an established enterprise. We help you find the right coverage to minimize risks and keep your business running smoothly.",
    benefits: [
      "Access to multiple carriers for competitive rates.",
      "Coverage tailored to your industry and business needs.",
      "Flexible payment options.",
      "Expert guidance to protect your assets and employees.",
      "Comprehensive claims support.",
    ],
    coverageItems: [
      "General Liability Insurance",
      "Commercial Property Insurance",
      "Workers’ Compensation",
      "Business Interruption Insurance",
      "Professional Liability Insurance",
    ],
    imageSrc: "/4Q.jpg",
    carriers: [
      { name: "ProGressive", logo: "/pg.png" },
      { name: "StateFarm", logo: "/sf.png" },
      { name: "Geico", logo: "/gc.png" },
      { name: "AllState", logo: "/as.png" },
      { name: "USAA", logo: "/ua.png" },
    ],
  };

  const formFields = [
    { name: 'businessName', label: 'Business Name', type: 'text', required: true },
    { name: 'contactName', label: "Contact Person's Name", type: 'text', required: true },
    { name: 'email', label: 'Email Address', type: 'email', required: true },
    { name: 'phone', label: 'Phone Number', type: 'tel', required: true },
    { name: 'address', label: 'Business Address', type: 'text', required: true },
    { name: 'businessType', label: 'Type of Business', type: 'text', required: true },
    { name: 'yearsInBusiness', label: 'Years in Business', type: 'number', required: true },
    { name: 'employees', label: 'Number of Employees', type: 'number', required: true },
    { name: 'annualRevenue', label: 'Annual Revenue', type: 'number', required: true },
    { name: 'startDate', label: 'Desired Coverage Start Date', type: 'date', required: true },
    { name: 'coverageType', label: 'Type of Coverage', type: 'custom-select', options: ['General Liability', 'Workers Compensation', 'Professional Liability', 'Property Insurance'], required: true },
    { name: 'currentProvider', label: 'Current Business Insurance Provider', type: 'text', required: false },
  ];

  const coverageTypeOptions = [
    { value: 'General Liability', label: 'General Liability' },
    { value: 'Workers Compensation', label: 'Workers Compensation' },
    { value: 'Professional Liability', label: 'Professional Liability' },
    { value: 'Property Insurance', label: 'Property Insurance' },
  ];

  return (
    <div>
      <InsuranceTypePage {...pageProps} />
      <section className="py-20 bg-background-dark">
        <div className="container mx-auto px-6">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-center mb-12 text-white"
          >
            Get Your Business Insurance Quote
          </motion.h2>
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            onSubmit={handleSubmit}
            className="max-w-3xl mx-auto bg-background-light p-8 rounded-lg shadow-md border-4 border-accent"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {formFields.map((field) => (
                field.type !== 'custom-select' ? (
                  <motion.div
                    key={field.name}
                    whileHover={field.type !== 'textarea' ? { scale: 1.05 } : {}}
                    whileTap={field.type !== 'textarea' ? { scale: 0.95 } : {}}
                    className="flex flex-col"
                  >
                    <label htmlFor={field.name} className="block text-text-dark mb-2">
                      {field.label}
                      {field.required && <span className="text-red-500">*</span>}
                    </label>
                    {field.type === 'textarea' ? (
                      <textarea
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                        rows="3"
                      />
                    ) : (
                      <input
                        type={field.type}
                        id={field.name}
                        name={field.name}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.required}
                        className="w-full p-2 bg-background-light text-white border-4 border-accent rounded-md "
                      />
                    )}
                  </motion.div>
                ) : (
                  <CustomDropdown
                    key={field.name}
                    label={field.label}
                    options={coverageTypeOptions}
                    selected={formData[field.name]}
                    onSelect={(selected) => handleSelect(field.name, selected)}
                    isMultiSelect={false} // Set to true if multi-select is needed
                    placeholder={`Select ${field.label}`}
                  />
                )
              ))}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className="mt-8 w-full bg-accent text-white font-bold py-2 px-6 rounded-md hover:bg-accent-light transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Get Your Quote'}
            </motion.button>
            {submitMessage && (
              <p className="mt-4 text-center text-white">{submitMessage}</p>
            )}
          </motion.form>
        </div>
      </section>
      <Services header="Types of Insurance" />
    </div>
  );
};

export default BusinessInsurance;
