import React, { useRef, useState, useEffect } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

const featureItems = [
  { title: 'Comprehensive Coverage', description: 'Tailored insurance solutions for all aspects of your life', icon: `fi fi-rs-shield-keyhole` },
  { title: 'Expert Advice', description: 'Guidance from seasoned insurance professionals', icon: 'fi fi-rs-head-side-brain' },
  { title: 'Country Wide Solutions', description: 'Providing comprehensive solutions across the nation.', icon: 'fi fi-rr-globe' },
  { title: 'Support', description: 'Round-the-clock assistance for your peace of mind', icon: 'fi fi-rr-clock-three' },
];

const Features = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });
  const controls = useAnimation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        const featureElements = ref.current.querySelectorAll('.feature-item');
        const middleOfViewport = window.innerHeight / 2;

        let closestFeature = null;
        let closestDistance = Infinity;

        featureElements.forEach((element, index) => {
          const rect = element.getBoundingClientRect();
          const distanceToMiddle = Math.abs(rect.top + rect.height / 2 - middleOfViewport);

          if (distanceToMiddle < closestDistance) {
            closestDistance = distanceToMiddle;
            closestFeature = index;
          }
        });

        if (closestFeature !== null) {
          setActiveIndex(closestFeature);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isMobile]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <section ref={ref} className="py-20 bg-background-dark text-white relative overflow-hidden">
      <div 
        className="absolute inset-0 opacity-20"
      />
      <div className="container mx-auto px-6 md:px-10 lg:px-20 max-w-8xl relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-white"
        >
          Why Choose RPIS
        </motion.h2>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'} gap-8`}
        >
          {featureItems.map((item, index) => (
            <motion.div
              key={index}
              className={`feature-item text-center bg-primary bg-opacity-10 rounded-lg p-6 transform transition-all duration-300 ${
                isMobile ? (index === activeIndex ? 'scale-105 shadow-lg' : '') : 'hover:scale-105 hover:shadow-lg'
              } relative group`}
            >
              <motion.div
                className={`absolute inset-0 bg-gradient-to-br from-accent to-secondary_alt rounded-lg transition-opacity duration-300 ${
                  isMobile ? (index === activeIndex ? 'opacity-20' : 'opacity-0') : 'opacity-0 group-hover:opacity-20'
                }`}
                initial={false}
                animate={{ scale: [0.9, 1.1, 1] }}
                transition={{ repeat: Infinity, duration: 5 }}
              />
              <div className="relative z-10">
                <div className="items-center justify-center mx-auto mb-8 flex">
                  <i className={item.icon + ' text-6xl text-secondary_alt'}></i>
                </div>
                <h3 className="text-xl font-semibold mb-2 text-white">{item.title}</h3>
                <p className="text-white">{item.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Features;