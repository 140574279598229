import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const testimonials = [
  { name: 'Jake Peterson', comment: 'Highly recommend!', details: "The team was incredibly professional and guided me through every step of the process." },
  { name: 'Sarah Linton', comment: 'Fantastic experience!', details: 'I felt supported and informed from start to finish. Great service!' },
  { name: 'Michael Robson', comment: 'Superb service!', details: 'They exceeded my expectations with fast responses and clear communication.' },
  { name: 'Laura Evans', comment: 'Very impressed!', details: 'Quick, efficient, and easy to work with. Highly recommend their services.' },
  { name: 'Daniel Harper', comment: 'Top-notch!', details: "Their attention to detail and care made the entire experience stress-free." },
  { name: 'Amy Richards', comment: 'Amazing team!', details: 'They handled everything smoothly and were very helpful throughout.' },
];

const Testimonials = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <section ref={ref} className="py-20 bg-background-light relative overflow-hidden">
      <motion.div
        className="absolute inset-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 1 }}
      />
      <div className="container mx-auto px-6 relative z-10">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 text-white"
        >
          Testimonials
        </motion.h2>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-background-dark p-6 rounded-lg shadow-md transition-all duration-300 hover:scale-105 border-4 border-white"
            >
              <div className="flex items-center mb-4">
                <div>
                  <h3 className="font-semibold text-white">{testimonial.name}</h3>
                  <div className="text-yellow-400">★★★★★</div>
                </div>
              </div>
              <p className="text-text-light mb-2">"{testimonial.comment}"</p>
              <p className="text-sm text-text-dark">{testimonial.details}</p>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials;