import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const OurMission = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <section ref={ref} className="py-20 bg-gradient-to-br from-background-dark to-background-light">
      <div className="container mx-auto px-6">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-12 text-accent-light"
        >
          Our Mission
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -50 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-white"
          >
            <p className="text-xl mb-6 leading-relaxed">
            At RPIS, our mission is to provide personalized and comprehensive insurance solutions by partnering with multiple carriers to ensure the best rates and coverage for our clients.
            </p>
            <p className="text-xl leading-relaxed">
            We strive to be more than just an insurance brokerage – we aim to be a trusted partner in our clients’ journey toward financial security and peace of mind. By offering tailored policies that adapt to their changing needs, we help them face life’s uncertainties with confidence.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : 50 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="bg-primary rounded-lg shadow-lg p-8"
          >
            <h3 className="text-2xl font-semibold mb-6 text-white">We are committed to:</h3>
            <ul className="space-y-4">
              {[
                "Providing personalized and outstanding customer care",
                "Customizing insurance plans to suit each client’s unique needs",
                "Upholding honesty and transparency in everything we do",
                "Leveraging modern technology to simplify the process",
                "Giving back to the communities we serve"
              ].map((item, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
                  transition={{ delay: 0.6 + index * 0.1, duration: 0.5 }}
                  className="flex items-center text-white"
                >
                  <svg className="w-6 h-6 mr-2 text-tertiary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  {item}
                </motion.li>
              ))}
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: isInView ? 1 : 0 }}
                transition={{ duration: 0.5, delay: 1.2}}
                className="bg-white text-primary px-8 py-3 rounded-md font-semibold text-lg hover:bg-accent-light hover:text-white transition duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => window.location.href = '/types-of-insurance'}
              >
                Explore Insurance Options
              </motion.button>
            </ul>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default OurMission;