import React from 'react';
import { motion } from 'framer-motion';
import AboutHero from '../components/AboutHero';
import OurMission from '../components/OurMission';
import OurTeam from '../components/OurTeam';
import OurHistory from '../components/OurHistory';
import Awards from '../components/Awards';

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <OurMission />
      <OurTeam />
      <OurHistory />
    </motion.div>
  );
};

export default About;